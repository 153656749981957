import {logger as baseLogger} from 'shared/utils/logger';

import type {UnifiedIdSettings} from 'types';

import type {Uid2Payload} from 'shared/utils/freestar-ads/types';

const logger = baseLogger.child({tag: '[UnifiedId Requester]'});

window.__uid2 = window.__uid2 || {};
window.__uid2.callbacks = window.__uid2.callbacks || [];

/**
 * Get or generate UID2 token
 * @param {string} emailHash
 * @param {UnifiedIdSettings | undefined} unifiedSettings
 * @return {Promise<string>}
 */
const getUid2Token = async (emailHash: string,
  unifiedSettings: UnifiedIdSettings | undefined): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    if (!unifiedSettings) {
      const error = 'Settings are not set!';
      logger.warn(error);
      return reject(new Error(error));
    }

    if (window.__uid2.callbacks.length > 1) {
      return resolve(window.__uid2.getIdentity().advertising_token as string);
    }

    window.__uid2.callbacks.push(async (eventType: string, payload: Uid2Payload) => {
      if (eventType === 'SdkLoaded') {
        window.__uid2.init({});
      }

      if (eventType === 'InitCompleted' && !payload.identity) {
        try {
          await window.__uid2.setIdentityFromEmailHash(emailHash,
            unifiedSettings,
          );

          const token = window.__uid2.getIdentity().advertising_token;
          if (token) {
            resolve(token);
          } else {
            reject(new Error('Cannot get token'));
          }
        } catch (e) {
          logger.warn(e.toString());
          reject(e);
        }
      } else if (eventType === 'InitCompleted' && payload.identity) {
        resolve(window.__uid2.getIdentity().advertising_token as string);
      }
    });
  });
};

export {getUid2Token};
