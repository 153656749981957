import {GOOGLE_AD_EMPTY_EVENT} from 'shared/constants';

import {analytics, triggerCustomEvent} from '..';

/**
 * Dispatches a custom event indicating a size change.
 * @param {string | number[] | null} size The size to be included in the event detail.
 * @return {void}
 */
function sizeChangeEvent(size: string | number[] | null) {
  const event = new CustomEvent('sizeChanged', {detail: {size} });
  window.dispatchEvent(event);
}

/**
   * Retrieves the ID from a URL.
   * @param {string} path The URL from which to retrieve the ID.
   * @return {string} The ID extracted from the URL.
   */
function retrieveIDFromUnitPath(path: string) {
  const parts = path.split('/');
  return parts[1]; // The ID is the second part after the first '/'
}

/**
    * Notifies when a slot cannot be created.
    * Logs the error, emits analytics events, and emits an event through eventEmitter.
    * @param {string} slotId the Google Slot ID.
    */
function notifyOnNullishSlot(slotId: string) {
  analytics.emitAdEvent('Error', {
    ad_error_code: 'google-slot-error',
    ad_error_log_response: 'Google can\'t create a slot',
    ad_error_source: 'gam',
    ad_unit_id: slotId,
  });
  triggerCustomEvent(GOOGLE_AD_EMPTY_EVENT, {});
}

/**
 * Convert base64 string to bytes array
 * @param {string} base64
 * @return {string}
 */
function base64ToHex(base64: string): string {
  const binaryString = atob(base64);
  return bytesToHex(new Uint8Array([...binaryString].map((char) => char.charCodeAt(0))));
}

/**
 * Convert bytes array to hex value
 * @param {Uint8Array} bytes
 * @return {string}
 */
function bytesToHex(bytes: Uint8Array): string {
  return Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

export {
  sizeChangeEvent,
  retrieveIDFromUnitPath as retrieveIDFromURL,
  notifyOnNullishSlot,
  base64ToHex,
};
