
// Otherwise import.meta.env will use ImportMetaEnv from the system.

import type {ImportMetaEnvLocal} from '../../../../vite-env';

// That is due the exporting an ImportMetaEnvLocal type from the vite-env.d.ts
const metaEnv = import.meta.env as ImportMetaEnvLocal;

/**
 * Checks if a given key exists in `import.meta.env`.
 *
 * This function verifies whether the specified key is a property of the `import.meta.env` object.
 * @template T - The key type corresponding to the environment variable name.
 * @param {T} key - The key to check for existence in `import.meta.env`.
 * @return {boolean} - Returns `true` if the key exists in `import.meta.env`, otherwise `false`.
 *
 * @example
 * // Assuming `import.meta.env` contains `{ VITE_APP_NAME: "MyApp" }`
 * const exists = isMetaEnv('VITE_APP_NAME'); // true
 * const notExists = isMetaEnv('VITE_UNKNOWN'); // false
 */
export function isMetaEnv<T extends keyof ImportMetaEnv>(key: ImportMetaEnv[T]): boolean {
  return key in import.meta.env;
}

/**
 * Parses an environment variable.
 *
 * If the variable exists in `import.meta.env`, the function tries to parse it as JSON.
 * If parsing fails due to a syntax error, the function returns the plain value of the variable.
 * If parsing fails due to other errors, the function throws the error.
 * If the variable does not exist in `import.meta.env`, the function returns the provided default value.
 *
 * @template T - The expected type of the environment variable value.
 * This influences the return type and is used to cast the value of the environment variable (if it exists).
 *
 * @param {string} envVarName - The name of the environment variable to parse.
 *
 * @param {string} defaultValue - The default value to return if the environment variable is not set.
 *
 * @return {string} - The parsed environment variable value if it exists and is valid JSON,
 * the plain value if it exists and is not valid JSON (syntax error during parsing),
 * or `defaultValue` if the environment variable is not set.
 *
 * @throws {Error} - Throws an error if parsing fails due to reasons other than a syntax error.
 */
function parseEnvVar<T extends keyof ImportMetaEnvLocal>(
  envVarName: T,
  defaultValue: ImportMetaEnvLocal[T],
): ImportMetaEnvLocal[T] {
  if (!isMetaEnv(envVarName)) return defaultValue;

  try {
    return JSON.parse(metaEnv[envVarName] as keyof ImportMetaEnvLocal);
  } catch (e) {
    if (e instanceof SyntaxError) {
      return metaEnv[envVarName];
    }

    throw e;
  }
}

export {parseEnvVar};
