
import {meteEnvConfig} from 'config';
import {bidRequestMetrics} from 'shared/api/metrics-service';
import {GPT_NAMED_SIZE} from 'shared/constants';
import {getUserId} from 'shared/utils';

import type {AdConfig, DeviceInfo} from 'types';

import type {EmailHash, FreestarParams} from 'shared/utils/freestar-ads/types';
import type {NamedSize} from 'types/google-ad';

/**
 * Find the key in an object by its associated value.
 * @param {string | number[] | null} renderedSize
 *
 * @return {string | undefined} The key associated with the given value, or undefined if not found.
 */
export function getKeyByValue(renderedSize: string | number[] | null): keyof NamedSize | undefined {
  return Object.keys(GPT_NAMED_SIZE).find((key) => {
    const objectValue = GPT_NAMED_SIZE[key as keyof NamedSize];
    if (Array.isArray(objectValue) && Array.isArray(renderedSize)) {
      return (
        objectValue.length === renderedSize.length && objectValue.every((el, index) => el === renderedSize[index])
      );
    }
    return objectValue === renderedSize;
  }) as keyof NamedSize;
}

/**
 * Determines whether a button should be displayed based on the rendered size.
 *
 * This function checks if the given dimensions (width and height) fall within an acceptable
 * range of aspect ratios. The base aspect ratio is defined as 1.2 (equivalent to 4:3),
 * with a tolerance of 10% in both directions.
 *
 * @param {string | number[] | null} renderedSize - The size of the rendered element. It should be
 *   an array with two numeric values representing [width, height]. If the input is not an array
 *   or doesn't contain two numbers, the function will return `false`.
 * @return {boolean} - Returns `true` if the given dimensions match the required aspect ratio
 *   within the allowed tolerance, otherwise `false`.
 *
 * Variables:
 * - `ASPECT_RATIO` (number): The base aspect ratio (4:3) represented as 1.2.
 * - `TOLERANCE` (number): The acceptable deviation from the base aspect ratio, defined as 10% (0.1).
 * - `width` (number): The width of the rendered element extracted from the input array.
 * - `height` (number): The height of the rendered element extracted from the input array.
 * - `aspectRatio` (number): The actual aspect ratio of the input dimensions, calculated as `width / height`.
 * - `minAspectRatio` (number): The minimum allowed aspect ratio, calculated as `ASPECT_RATIO * (1 - TOLERANCE)`.
 * - `maxAspectRatio` (number): The maximum allowed aspect ratio, calculated as `ASPECT_RATIO * (1 + TOLERANCE)`.
 */
export function shouldDisplayButton(renderedSize: string | number[] | null): boolean {
  const ASPECT_RATIO = 1.2;
  const TOLERANCE = 0.1;

  if (!Array.isArray(renderedSize) || renderedSize.length !== 2) {
    return false;
  }

  const [width, height] = renderedSize;

  if (typeof width !== 'number' || typeof height !== 'number' || height === 0) {
    return false;
  }

  const aspectRatio = parseFloat((width / height).toFixed(10));
  const minAspectRatio = parseFloat((ASPECT_RATIO * (1 - TOLERANCE)).toFixed(10));
  const maxAspectRatio = parseFloat((ASPECT_RATIO * (1 + TOLERANCE)).toFixed(10));

  const isWithinBounds = aspectRatio >= minAspectRatio && aspectRatio <= maxAspectRatio;

  return isWithinBounds;
}

export const getUserHashes = (deviceProperties: DeviceInfo | null): EmailHash | null => {
  if (!deviceProperties) {
    return null;
  }

  const eids = {} as EmailHash;
  const hasAlternateIds = !!deviceProperties.alternateIds;

  if (hasAlternateIds) {
    // Use alternateIds if available
    const sha256 = getUserId(deviceProperties, 'sha256');
    const sha1Id = getUserId(deviceProperties, 'sha1');
    const criteoMd5 = getUserId(deviceProperties, 'md5', 'criteo');

    if (sha256) {
      eids.sha256 = sha256;
    }
    if (sha1Id) {
      eids.sha1 = sha1Id;
    }
    if (criteoMd5) {
      eids.md5 = criteoMd5;
    }
    bidRequestMetrics.emitEvent('HouseholdIDs', [{Name: 'property', Value: 'alternateIds'}]);
  } else {
    // Fallback to user_identifiers
    const uid2Id = getUserId(deviceProperties, 'uid2');
    if (uid2Id) {
      eids.sha256 = uid2Id;
    }
    bidRequestMetrics.emitEvent('HouseholdIDs', [{Name: 'property', Value: 'user_identifiers'}]);
  }

  return Object.keys(eids).length > 0 ? eids : null;
};

/**
 * Generates and returns the Google configuration data required for Freestar ads.
 *
 * @param {AdConfig} adUnitConfig - The configuration object for the ad unit.
 * @param {DeviceInfo} deviceProperties - The properties of the device on which the ad will be displayed.
 *
 * @return {FreestarParams} - The parameters needed for initializing and managing Freestar ads.
 *
 * @typedef {Object} AdConfig - The configuration object for the ad unit.
 * @property {Object} providers - The providers configuration.
 * @property {Object} providers.gabriel - The Gabriel provider configuration.
 * @property {Object} providers.gabriel.googleTagManager - The Google Tag Manager configuration.
 *
 * @typedef {Object} DeviceInfo - The properties of the device.
 * @property {Object} ad_info - The advertising information of the device.
 * @property {string} ad_info.ifa - The identifier for advertisers.
 * @property {boolean} ad_info.lmt - The limit ad tracking flag.
 * @property {string} ad_info.ifa_type - The type of identifier for advertisers.
 * @property {Object} user_identifiers - The user identifiers.
 * @property {string} user_identifiers.uid2 - The hashed email of the user.
 * @property {boolean} coppa - The flag indicating if the content is child-directed.
 *
 * @typedef {Object} FreestarParams - The parameters needed for Freestar ads.
 * @property {boolean} eagerLoading - The flag indicating if ads should be eagerly loaded.
 * @property {string} hashedEmail - The hashed email of the user.
 * @property {boolean} coppa - The flag indicating if the content is child-directed.
 * @property {DeviceInfo} deviceProperties - The properties of the device.
 * @property {[[number]]} slotSizes - The sizes of the ad slots.
 * @property {string[]} scriptURLs - The script URLs for Freestar.
 * @property {Object[]} slots - The ad slots configuration.
 * @property {string} slots.placementName - The name of the ad placement.
 * @property {string} slots.slotId - The ID of the ad slot.
 * @property {Object[]} slots.targeting - The targeting parameters for the ad slot.
 * @property {string} slots.targeting.key - The key of the targeting parameter.
 * @property {string|string[]} slots.targeting.value - The value of the targeting parameter.
 */
export function getGoogleConfigData(
  adUnitConfig: AdConfig, deviceProperties: DeviceInfo,
): FreestarParams {
  const googleTagManager = adUnitConfig.providers?.gabriel.googleTagManager;
  const {targeting} = googleTagManager ?? meteEnvConfig.ads.gpt;
  const eagerLoading = googleTagManager?.eagerLoading ?? meteEnvConfig.ads.gpt.eagerLoading;
  const coppa = deviceProperties?.coppa;

  const targetingProfiles = targeting && Object.keys(targeting).map((key)=> {
    return {key, value: targeting[key]};
  });

  const [v83Url, v120Url] = googleTagManager?.scriptURLs ?? [];

  const hashes = getUserHashes(deviceProperties);

  return {
    eagerLoading,
    hashes,
    coppa,
    deviceProperties,
    slotSizes: googleTagManager?.slotSizes,
    scriptURLs: [v83Url, v120Url].some((url) => url) ? [v83Url, v120Url] : undefined,
    slots: [{
      placementName: googleTagManager?.placementName ?? '',
      slotId: googleTagManager?.slotPlacementId ?? '',
      targeting: [{
        key: 'ifa',
        value: deviceProperties?.ad_info.ifa ?? '',
      },
      ...(targetingProfiles?.length ? targetingProfiles : []),
      ],
    }],
  };
}
