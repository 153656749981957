import type {ReactElement, ReactNode} from 'react';

import TellyAd from 'components/Ads/Telly';
import {getDefaultAdConfig} from 'config';
import {useGetConfig} from 'shared/api/bootstrap-service';
import {globalStorage} from 'shared/utils/globalStorage';
import {logger as baseLogger} from 'shared/utils/logger';

import {AdUnitConfigContext} from './AdUnitConfigContext';
import {validateAdConfig} from './helpers/validation-schema';

const logger = baseLogger.child({tag: '[AdUnitConfigContextProvider]'});

const AdUnitConfigContextProvider = ({children}: {children: ReactNode}): ReactElement | null => {
  const {data, error} = useGetConfig();
  const defaultAdUnitConfig = getDefaultAdConfig();

  logger.debug({data, defaultAdUnitConfig, httpError: error});

  if (!data) {
    return <TellyAd reason='startup' loaded={false}/>; // should be empty when it's loading
  }

  const {error: validationError} = validateAdConfig(data);

  const adConfig = (validationError || error || !data) ? defaultAdUnitConfig : data;

  if (!validationError && !error) {
    logger.info('Received configuration from the service bootstrap', {adConfig});
  }

  globalStorage.adUnitConfig = adConfig;

  return (
    <AdUnitConfigContext.Provider
      value={{
        adUnitConfig: adConfig,
      }}
    >
      {children}
    </AdUnitConfigContext.Provider>
  );
};

export default AdUnitConfigContextProvider;
