import {logger as baseLogger} from 'shared/utils/logger';

import type {FetchBidsArguments, UAMBid} from './types';

const logger = baseLogger.child({tag: '[Amazon Ads Manager]'});

/**
 * Class representing the Amazon Ads Manager.
 */
export class AmazonAdsManager {
  private static instance: AmazonAdsManager | null = null;
  private script: HTMLScriptElement | null = null;

  /**
   * Creates an instance of AmazonAdsManager.
   * @private
   */
  private constructor() {
    const urlParams = new URLSearchParams(window.location.search);
    const isAdamVideoUnit = urlParams.get('ad-unit') == 'ADAM_SCREENSAVER_VIDEO_UNIT';

    let debugMessage = 'Initialize of Amazon ADS was skipped';

    if (!isAdamVideoUnit) {
      const links = ['https://a.pub.network/', 'https://b.pub.network/', 'https://c.pub.network/',
        'https://d.pub.network/', 'https://btloader.com/', 'https://api.btloader.com/',
        'https://confiant-integrations.global.ssl.fastly.net', 'https://a.pub.network/freetelly-com/cls.css'];

      links.forEach((href) => {
        const link = document.createElement('link');
        link.href = href;
        link.rel = 'preconnect';
        link.crossOrigin = 'true';
        document.head.appendChild(link);
      });
      const scriptSrc = '//imasdk.googleapis.com/pal/sdkloader/pal.js';
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      script.onload = () => {
        this.embedScriptAsync();
      };
      document.head.appendChild(script);
      debugMessage = 'Initialized Amazon ADS';
    }
    logger.debug(debugMessage);
  }

  /**
   * Gets the singleton instance of AmazonAdsManager.
   * @return {AmazonAdsManager} The AmazonAdsManager instance.
   */
  public static getInstance(): AmazonAdsManager {
    if (!AmazonAdsManager.instance) {
      AmazonAdsManager.instance = new AmazonAdsManager();
    }
    return AmazonAdsManager.instance;
  }

  /**
   * Fetches bids for the specified slot.
   * @param {FetchBidsArguments} {slots, timeout} - The slot ID.
   * @param {CallableFunction} callback
   * @return {void}
   */
  public fetchBids({slots, timeout}: FetchBidsArguments, callback: (bids: UAMBid[]) => void): void {
    logger.debug('Fetching bids for slots:', {slots});
    window.googletag.cmd.push(() => {
      window.apstag.fetchBids({slots, timeout}, callback);
    });
  }

  /**
 * Sets the display bids using the Amazon Publisher Services (APS) library.
 * This method should be called after fetching bids using APS.
 * It instructs the APS library to display the bids in the ad slots defined.
 * @public
 * @function
 * @name setDisplayBids
 * @return {void}
 */
  public setDisplayBids(): void {
    window.apstag.setDisplayBids();
    logger.debug('Display bids set.');
  }

  /**
   * Removes this.script from DOM
   */
  public destroy(): void {
    const apsTag = document.getElementById('aps-tag');
    if (apsTag) {
      document.head.removeChild(apsTag);
      logger.debug('Apstag script removed from DOM');
    } else {
      logger.warn('Apstag script not found in DOM');
    }
    this.script = null;
  }

  /**
   * Initializes the Apstag script.
   * @private
   */
  private embedScriptAsync() {
    const inTheHead = document.getElementById('aps-tag');
    if (!inTheHead) {
      this.script = document.createElement('script');
      this.script.async = true;
      this.script.id = 'aps-tag';
      // eslint-disable-next-line max-len
      this.script.innerHTML = '!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)[0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");';
      document.head.appendChild(this.script);
      logger.debug('Apstag script initialized');
    }

    window.apstag.init({
      pubID: '63ce456f-988c-44ab-803c-aa9b2e399f2d',
      adServer: 'googletag',
    });
  }
}

const amazonAdsManager = AmazonAdsManager.getInstance();

export {amazonAdsManager};
