// export type ImportMetaEnvLocal = ImportMetaEnv;

import {parseEnvVar} from 'shared/api/parse-env-var';

const config = {
  ads: {
    // If you want to force a Gabriel show only one type of ads, you can define here required ads type
    types: parseEnvVar('VITE_ADS__TYPES', undefined),
    // ad strategy
    strategy: parseEnvVar('VITE_AD__STRATEGY', undefined),
    // ad unit. Possible variants: EVE_FULLSCREEN_UNIT ADAM_SCREENSAVER_VIDEO_UNIT ADAM_SCREENSAVER_HOUSE_AD_UNIT_UP ADAM_SCREENSAVER_HOUSE_AD_UNIT_DOWN
    adUnit: parseEnvVar('VITE_AD__UNIT', 'EVE_FULLSCREEN_UNIT'),
    // google publisher tag sizes
    gpt: {
      targeting: parseEnvVar('VITE_GPT__TARGETING', undefined),
      slotId: parseEnvVar('VITE_GPT__SLOT_ID', '22982538162'),
      eagerLoading: parseEnvVar('VITE_GPT__EAGER_LOADING', false),
    },
    video: {
      mute: parseEnvVar('VITE_VIDEO__MUTE', 1),
      autoplay: parseEnvVar('VITE_VIDEO__AUTOPLAY', 1),
    },
    debug: {
      tellySdkClickEvents: parseEnvVar('VITE_DEBUG__TELLY__SDK__CLICK__EVENTS', false),
    },
  },
  device: {
    // For development purposes only, mock of device information to allow making actual AdRequests
    mock: parseEnvVar('VITE_DEVICE__MOCK', null),

    // ratio between actual dimensions and design
    scaleFactor: parseEnvVar('VITE_DEVICE__SCALING_FACTOR', 1.0),
  },
  /**
   * Environments tell you where an error occurred, whether that's in your production system, your staging server,or elsewhere.
   * Sentry automatically creates an environment when it receives an event with the environment parameter set.
   * @see https://docs.sentry.io/platforms/javascript/configuration/environments/
   */
  environment: parseEnvVar('VITE_APP_ENVIRONMENT', 'development'),
  launchdarklyClientSideID: parseEnvVar('VITE_LD_CLIENT_SIDE_ID', '66be295301e6a30f75d0e0ff'),
  logger: {
    pino: {
      defaultLoggingLevel: parseEnvVar('VITE_LOGGER_PINO_DEFAULT_LEVEL', 'debug'),
    },
    // configuration for Sentry service (https://sentry.io)
    sentry: {
      // Is that service enabled in the current environment or not
      isEnabled: parseEnvVar('VITE_LOGGER__SENTRY__ISENABLED', true),
      /**
       * The Sentry SDKs have two configuration options to control the volume of transactions sent to Sentry, allowing you to take a representative sample
       * Uniform sample rate (tracesSampleRate):
       * Provides an even cross-section of transactions, no matter where in your app or under what circumstances they occur.
       * @see https://docs.sentry.io/platforms/javascript/configuration/sampling/#configuring-the-transaction-sample-rate
       */
      tracesSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__TRACESSAMPLERATE', 1.0),
      /**
       * The sample rate for replays that begin recording immediately and last the entirety of the user's session.
       * @see https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/#sampling
       */
      replaysSessionSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__REPLAYSSESSIONSAMPLERATE', 0.1),
      /**
       * The sample rate for replays that are recorded when an error happens. This type of replay will record up to a minute of events prior to the error and continue recording until the session ends.
       * @see https://docs.sentry.io/platforms/javascript/guides/capacitor/session-replay/#sampling\
       */
      replaysOnErrorSampleRate: parseEnvVar('VITE_LOGGER__SENTRY__REPLAYSERRORSAMPLERATE', 1.0),
    },
  },
  adUnitConfig: {
    baseURL: parseEnvVar('UNIFIED_ID_BASE_URL', ''),
    subscriptionId: parseEnvVar('UNIFIED_ID_SUBSCRIPTION', ''),
    serverPublicKey: parseEnvVar('UNIFIED_ID_PUBLIC_KEY', ''),
  },
};

export type MetaEnvConfigType = typeof config

export default config;
