import {AdTypes} from 'features/adoppler';
import {gabrielAnalyticMetrics, gabrielGoogleMetrics,
  type MetricsService, gabrielLifecycleMetrics} from 'shared/api/metrics-service';
import {FREESTAR_NOT_LOADED_EVENT, GOOGLE_AD_EMPTY_EVENT} from 'shared/constants';
import {analytics, triggerCustomEvent} from 'shared/utils';
import {amazonAdsManager} from 'shared/utils/amazon-ads';
import {AndroidSDKEvent} from 'shared/utils/eventsSdk';
import {logger as baseLogger} from 'shared/utils/logger';

import {retrieveIDFromURL, sizeChangeEvent, base64ToHex} from './helpers';

import type {EmailHash, FreestarParams} from './types';

const logger = baseLogger.child({tag: '[Freestar Ads]'});
interface GamRemoteInfo extends googletag.ResponseInformation {
  isBackfill: boolean;
}

/**
 * Class representing Freestar functionalities.
 */
export class Freestar {
  private refreshIntervalId: NodeJS.Timeout | undefined;
  private scriptURLs: [v120Url: string, v83Url: string] | undefined;
  private metricsGabrielGoogle: MetricsService;
  private metricsGabrielAdAnalytics: MetricsService;
  private cancelTimeout: NodeJS.Timeout | undefined;

  public googleSlotId: string | undefined;
  public creativeId: string | undefined;
  public advertiser_name: string | undefined;
  public wasRendered: boolean = false;
  public isBackfill: boolean = false;

  slotRequested: ((event: googletag.events.SlotRequestedEvent) => void) | undefined;
  slotRenderEnded: ((event: googletag.events.SlotRenderEndedEvent) => void) | undefined;
  slotOnload: ((event: googletag.events.SlotOnloadEvent) => void) | undefined;

  /** Uses for preventing refreshing the AD if at present time
   * displays the Adoppler ad. This field gives an opportunity
   * to refresh the AD only if Freestar playing.
   */
  public adsenseWasPlayed: boolean = true;

  /**
   * @constructor
   */
  constructor() {
    this.metricsGabrielGoogle = gabrielGoogleMetrics;
    this.metricsGabrielAdAnalytics = gabrielAnalyticMetrics;

    /**
     * Handles the slot requested event from GPT.
     * This function is triggered when a slot request is made.
     *
     * @param {googletag.events.SlotRequestedEvent} event - The event object representing the slot requested.
     * @return {void}
     */
    this.slotRequested = (event: googletag.events.SlotRequestedEvent): void => {
      logger.debug('Slot slotRequested');
      const adUnitPath = event.slot.getAdUnitPath();
      const googleSlotId = retrieveIDFromURL(adUnitPath);

      gabrielLifecycleMetrics.emitEvent('AdSlotRefreshStart');
      analytics.emitAdEvent('Request', {
        ad_server: 'gam',
        ad_unit_id: googleSlotId,
      });

      this.metricsGabrielGoogle.emitEvent('Request');
      this.metricsGabrielAdAnalytics.emitEvent('AdRequest', [
        {Name: 'type', Value: 'Freestar'},
      ]);
    };

    /**
     * Handles slot rendered event
     * @param {googletag.events.SlotRenderEndedEvent} event
     */
    this.slotRenderEnded = (event: googletag.events.SlotRenderEndedEvent) => {
      logger.debug('Slot SlotRenderEndedEvent');
      const targetSlot = 'native-ad-fs';
      const slot = event.slot.getSlotElementId();

      if (slot === targetSlot) {
        if (event.isEmpty) {
          if (__DEV__) {
            window.googletag.pubads().set('page_url', 'https://www.telly.com/');
          }
          this.wasRendered = false;
          logger.debug('Get no ads in the Google response', event);

          this.triggerAnalyticError('Get no ads in the Google response');
          this.triggerMetricError();
          this.resetTimeout();
          triggerCustomEvent(GOOGLE_AD_EMPTY_EVENT, {timestamp: Date.now()});
        } else {
          const sdkEvent = new AndroidSDKEvent();
          sdkEvent.adStarted('gpt', undefined);
          sizeChangeEvent(event.size);
          this.wasRendered = true;
        }
      } else if (!event.slot) {
        logger.debug(`Can't create a slot`);

        this.triggerAnalyticError(`Can't create a slot`);
        this.triggerMetricError();
        this.wasRendered = false;
        this.resetTimeout();
        triggerCustomEvent(GOOGLE_AD_EMPTY_EVENT, {timestamp: Date.now()});
      }
    };

    /**
     * Handles the event when a GPT slot render ends.
     * @param {googletag.events.SlotOnloadEvent} event The event object representing the slot render.
     * @return {void}
     */
    this.slotOnload = (event: googletag.events.SlotOnloadEvent): void => {
      logger.debug('Slot slotOnload');

      const slotInfo = event.slot.getResponseInformation() as unknown as GamRemoteInfo;

      const adUnitPath = event.slot.getAdUnitPath();
      const googleSlotId = retrieveIDFromURL(adUnitPath);
      const creativeId = slotInfo?.creativeId?.toString();
      const campaignId = slotInfo?.campaignId?.toString();
      const advertiserId = slotInfo?.advertiserId?.toString();
      const isBackfill: boolean = Boolean(slotInfo?.isBackfill);
      this.sendInfoLoggerInfo(creativeId, campaignId);

      logger.debug('Slot rendered with the creative ID:', creativeId);
      this.googleSlotId = googleSlotId;
      this.creativeId = creativeId;
      this.advertiser_name = advertiserId;
      this.adsenseWasPlayed = false;
      this.isBackfill = isBackfill;

      analytics.emitAdEvent('Impression', {
        ad_server: 'gam',
        creative_id: this.creativeId,
        advertiser_name: this.advertiser_name,
        ad_unit_id: this.googleSlotId,
        creative_permutation_type: 'eve_house_ad_emptyadpanel',
      });

      const responseType = this.isBackfill ? 'house' : 'paid';

      this.metricsGabrielGoogle.emitEvent('Response', [{Name: 'status', Value: responseType}]);
      this.metricsGabrielAdAnalytics.emitEvent('AdImpression', [
        {Name: 'type', Value: 'Freestar'},
        {Name: 'source', Value: 'Freestar'},
        {Name: 'status', Value: responseType},
      ]);

      // we have ad timeout, slot was rendered so we should clear it
      this.resetTimeout();
    };
  }

  /**
   * we have ad timeout, slot was rendered so we should clear it
   * @private
   */
  private resetTimeout() {
    if (this.cancelTimeout) {
      clearTimeout(this.cancelTimeout);
      this.cancelTimeout = undefined;
    }
  }

  /**
   * Initializes the Freestar instance with script URLs.
   * @param {string[]} scriptURLs - The script URLs for Freestar.
   * @private
   */
  private async init(scriptURLs: [v83Url: string, v120Url: string]) {
    this.scriptURLs = scriptURLs;
    return new Promise<void>((resolve, reject) => {
      const script = document.getElementById('pubfig-script');
      if (!script) {
        this.loadScriptAsync().catch((e) => {
          reject(e);
        }).then(() => {
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  /**
 * Loads the script asynchronously and returns a Promise.
 * @private
 * @return {Promise<HTMLScriptElement>} A Promise that resolves with the loaded script element if successful, otherwise rejects with an error.
 */
  private loadScriptAsync(): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      const scriptUrl = this.getScriptUrl();

      // Handle the case where scriptUrl is undefined
      if (!scriptUrl) {
        const error = new Error('Script URL is undefined.');
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {});
        return reject(error);
      }

      const script = document.createElement('script');
      script.id = 'pubfig-script';
      script.src = scriptUrl;
      script.async = true;

      script.onload = () => {
        resolve(script);
      };

      script.onerror = (e) => {
        document.head.removeChild(script);
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {});
        reject(e);
      };

      document.head.appendChild(script);
    });
  }

  /**
   * Detects the version of Chrome browser from the User-Agent string.
   * @private
   * @return {?number} The version of Chrome browser if detected, otherwise null.
   */
  private detectChromeVersion(): number | null {
    const ua = navigator.userAgent;
    const chromeMatch = ua.match(/Chrome\/(\d+)/);
    return chromeMatch ? parseInt(chromeMatch[1]) : null;
  }

  /**
 * Gets the URL of the script based on the detected Chrome version.
 * Note: This is a temporary solution.
 * @private
 * @return {string | undefined} The URL of the script, or undefined if no "scriptURLs" are provided.
 */
  private getScriptUrl(): string | undefined {
    const chromeVersion = this.detectChromeVersion();

    // Ensure scriptURLs are valid
    if (!this.scriptURLs || this.scriptURLs.length < 2) {
      logger.warn('No "scriptURLs" provided or insufficient URLs.');
      return undefined;
    }

    const [v83Url, v120Url] = this.scriptURLs;

    // Return the correct URL based on the detected Chrome version
    switch (chromeVersion) {
      case 120:
        return v120Url;
      case 83:
        return v83Url;
      default:
        return v83Url;
    }
  }

  /**
   * Removes all initialized scripts and links from the document head.
   */
  public destroy() {
    const freestar = window.freestar || {};
    freestar.queue = window.freestar?.queue || [];

    freestar.queue.push(() => {
      window.googletag.pubads().removeEventListener('slotRequested', this.slotRequested!);
      window.googletag.pubads().removeEventListener('slotRenderEnded', this.slotRenderEnded!);
      window.googletag.pubads().removeEventListener('slotOnload', this.slotOnload!);
      window.googletag.destroySlots();
      freestar.deleteAdSlots();
      amazonAdsManager.destroy();
    });
    clearTimeout(this.refreshIntervalId);
    logger.debug('Slots destroyed');
  }

  /**
 * Adds slots and hashed email to the Freestar configuration.
 * @param {FreestarParams} params - The parameters for adding slots and hashed email.
 * @param {FreestarSlot[]} params.slots - The slots to add.
 * @param {EmailHash} [params.hashes] - The hashed email from device properties.
 * @param {DeviceInfo} params.deviceProperties - The device properties.
 * @param {boolean} [params.coppa] - Indicates if the content is child-directed.
 * @param {[[number]]} [params.slotSizes] - The slot sizes.
 * @param {[string, string]} params.scriptURLs - The script URLs for Freestar.
 */
  public async addHashedEmailAndSlots({
    slots,
    hashes,
    deviceProperties,
    coppa,
    slotSizes,
    scriptURLs,
  }: FreestarParams) {
    return new Promise<void>((resolve, reject) => {
    // Check if scriptURLs are provided; log a warning and reject the promise if not
      if (!scriptURLs || scriptURLs.length < 2) {
        const error = new Error('scriptURLs are missing or insufficient.');
        logger.warn('Freestar configuration requires valid "scriptURLs".', error);
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {timestamp: Date.now()});
        return reject(error);
      }

      // Proceed with Freestar initialization
      this.init(scriptURLs).then(() => {
        logger.debug('add hashed email and slots');
        window.googletag = window.googletag || {cmd: []};

        const freestar = window.freestar || {};
        freestar.queue = window.freestar?.queue || [];
        freestar.config = window.freestar?.config || {};
        freestar.config.targeting = freestar.config.targeting || [];
        freestar.debug = __DEV__;
        freestar.config.enabled_slots = slots;

        this.pushHashedEmail(hashes);

        freestar.queue.push(() => {
          logger.debug('Init googletag');
          window.googletag.pubads().addEventListener('slotRequested', this.slotRequested!);
          window.googletag.pubads().addEventListener('slotRenderEnded', this.slotRenderEnded!);
          window.googletag.pubads().addEventListener('slotOnload', this.slotOnload!);

          const placementTargeting: {[key: string]: string | string[]} = {};

          const isLat = Boolean(deviceProperties?.ad_info?.lmt);
          const rdid = deviceProperties?.ad_info?.ifa;
          const idtype = deviceProperties?.ad_info?.ifa_type || 'adid';

          slots.forEach((slot) => {
            amazonAdsManager.fetchBids(
              {
                slots: [
                  {
                    slotID: slot.slotId,
                    slotName: slot.placementName,
                    sizes: slotSizes!,
                  },
                ],
                timeout: 2e3,
              },
              function(bids) {
                freestar.queue.push(function() {
                  amazonAdsManager.setDisplayBids();
                  logger.debug('APS Bids:', bids);
                });
              },
            );
            slot.targeting.forEach(({key, value})=> {
              window.googletag.pubads().setTargeting(key, value);
              placementTargeting[key] = value;
            });

            // Set privacy settings and additional targeting parameters
            window.googletag.pubads().setPrivacySettings({
              childDirectedTreatment: coppa,
              limitedAds: isLat,
            });

            window.googletag.pubads().setTargeting('is_lat', isLat.toString());
            window.googletag.pubads().setTargeting('rdid', rdid);
            window.googletag.pubads().setTargeting('idtype', idtype);

            freestar.config.targeting.push({
              [slot.placementName]: placementTargeting,
            });
          });
        });

        if (freestar.initCallbackCalled) {
          freestar.queue.push(function() {
            freestar.newAdSlots(freestar.config.enabled_slots);
            logger.debug('Added slots:', freestar.config.enabled_slots);
          });
        } else {
          const SKIP_FREESTAR_TIMEOUT = 7000;
          this.cancelTimeout = setTimeout(() => {
            logger.debug('skip freestar');

            this.triggerAnalyticError('skip freestar because of timeout');
            this.triggerMetricError();

            triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {timestamp: Date.now()});
            reject(new Error('skip freestar because of timeout'));
          }, SKIP_FREESTAR_TIMEOUT);
        }

        freestar.initCallback = function() {
          logger.debug('freestar init callback', freestar.initCallbackCalled, freestar.config.enabled_slots.length);
          freestar.queue.push(function() {
            if (__DEV__) {
              window.googletag.pubads().set('page_url', 'freetelly.com');
            }
            freestar.newAdSlots(freestar.config.enabled_slots);
            logger.debug('Added slots initial:', freestar.config.enabled_slots);
          });
        };
        resolve();
      }).catch((e) => {
        logger.warn('Failed to initialize Freestar:', e);
        triggerCustomEvent(FREESTAR_NOT_LOADED_EVENT, {timestamp: Date.now()});
        reject(e);
      });
    });
  }

  /**
   * Refresh Ad
   */
  public refreshAd() {
    const freestar = window.freestar || undefined;

    if (freestar) {
      logger.debug('Refresh FreeStar Ad');
      freestar.queue.push(function() {
        freestar.refresh();
      });
    }
  }

  /**
   * Adds an event listener for the 'sizeChanged' event.
   * @param {CallableFunction} handler The event handler function to be called when the size changes.
   */
  public addSizeChangedEventListener(handler: (event: CustomEvent<{size: string | number[] | null;}>) => void) {
    window.addEventListener('sizeChanged', handler as EventListener);
  }

  /**
   * Removes an event listener for the 'sizeChanged' event.
   * @param {CallableFunction} handler The event handler function to be called when the size changes.
   */
  public removeSizeChangedEventListener(handler: (event: CustomEvent<{size: string | number[] | null;}>) => void) {
    window.removeEventListener('sizeChanged', handler as EventListener);
  }

  /**
   * Retrieves the creative ID of the current ad slot.
   * @return {string | undefined} The creative ID of the current ad slot if available, otherwise undefined.
   */
  public getCreativeId(): string | undefined {
    if (window.freestar?.initCallbackCalled) {
      const adSlotName = window.freestar.newAdSlotIds[0];

      return window.freestar.dfpSlotInfo[adSlotName]?.creativeId?.toString();
    }
  }

  /**
 * Logs information about the rendered ad if the Freestar initialization callback has been called.
 *
 * @private
 * @param {string | undefined} creativeId - The unique identifier of the ad creative, if available.
 * @param {string | undefined} campaignId - The unique identifier of the ad campaign, if available.
 */
  private sendInfoLoggerInfo(creativeId: string | undefined, campaignId: string | undefined) {
    if (window.freestar?.initCallbackCalled) {
      logger.info('Render event ad with details', {
        adType: AdTypes.Google,
        isAcr: false,
        creativeId,
        campaignId,
        'type-of-ad': 'GAM',
      });
    }
  }

  /**
   * Pushes a hashed email to the Freestar identity queue.
   * The hashed email is set as the identity using SHA-256 hashing algorithm.
   * @param {EmailHash | null} hashes The hashed email to be pushed.
   * It should be a SHA-256 hashed string representing the email.
   */
  private pushHashedEmail(hashes: EmailHash | null) {
    const freestar = window.freestar || {};
    freestar.queue = window.freestar?.queue || [];

    if (hashes) {
      const shaHashes = {...hashes};
      shaHashes.sha256 = base64ToHex(hashes.sha256);
      freestar.queue.push(function() {
        freestar.identity.setIdentity({
          hashes: shaHashes,
        });
      });
    } else {
      logger.warn('No "user_identifiers.uid2" provided.');
    }
  }

  /**
   * Trigger analytic module
   */
  public triggerAnalytic() {
    if (this.googleSlotId) {
      analytics
        .emitAdEvent('Request', {
          ad_server: 'gam',
          ad_unit_id: this.googleSlotId,
        });

      if (this.wasRendered) {
        analytics.emitAdEvent('Impression', {
          ad_server: 'gam',
          creative_id: this.creativeId,
          advertiser_name: this.advertiser_name,
          ad_unit_id: this.googleSlotId,
          creative_permutation_type: 'eve_house_ad_emptyadpanel',
        });
      }
      logger.debug('Triggering analytics event for ad unit:', this.googleSlotId);
    }
  }

  /**
   * Emit metric error
   * @private
   */
  private triggerMetricError() {
    const responseType = 'empty';

    this.metricsGabrielGoogle.emitEvent('Response', [{Name: 'status', Value: responseType}]);
    this.metricsGabrielAdAnalytics.emitEvent('AdError', [
      {Name: 'type', Value: 'Freestar'},
      {Name: 'source', Value: 'Freestar'},
      {Name: 'status', Value: responseType},
    ]);
  }

  /**
   * Emit analytic error
   * @param {string} error
   * @private
   */
  private triggerAnalyticError(error: string) {
    analytics.emitAdEvent('Error', {
      ad_error_code: `error`,
      ad_error_log_response: error,
      ad_error_source: 'gam',
    });
  }

  /**
   * Trigger metrics module
   */
  public triggerMetric(): void {
    if (this.googleSlotId) {
      this.metricsGabrielGoogle.emitEvent('Request');
      this.metricsGabrielAdAnalytics.emitEvent('AdRequest', [
        {Name: 'type', Value: 'Freestar'},
      ]);
      logger.debug('Triggering metrics event for ad unit:', this.googleSlotId);
    }

    let responseType = 'empty';
    if (this.wasRendered) {
      responseType = this.isBackfill ? 'house' : 'paid';
    }
    this.metricsGabrielGoogle.emitEvent('Response', [{Name: 'status', Value: responseType}]);
    this.metricsGabrielAdAnalytics.emitEvent('AdImpression', [
      {Name: 'type', Value: 'Freestar'},
      {Name: 'source', Value: 'Freestar'},
      {Name: 'status', Value: responseType},
    ]);
  }
}

const freestarManager = new Freestar();

export default freestarManager;
