import React, {useSyncExternalStore} from 'react';

import TellyAd from 'components/Ads/Telly';
import {logger as baseLogger} from 'shared/utils/logger';

const logger = baseLogger.child({tag: '[Offline Handler]'});

/**
 * Subscribes to online and offline events and invokes the provided callback
 * function when these events occur. Returns a cleanup function to remove the
 * event listeners when no longer needed.
 *
 * @param {function(Event): void} callback - The callback function to be invoked
 * when online or offline events occur. It receives an Event object as an argument.
 * @return {function(): void} - A cleanup function that removes the event listeners.
 */
function subscribe(callback: (event: Event) => void): () => void {
  window.addEventListener('online', callback);
  window.addEventListener('offline', callback);
  return () => {
    window.removeEventListener('online', callback);
    window.removeEventListener('offline', callback);
  };
}

/**
 * Retrieves the current online status and returns a boolean value indicating
 * whether the browser is online (true) or offline (false).
 *
 * @return {boolean} - `true` if the browser is online, `false` if it is offline.
 */
function getSnapshot(): boolean {
  return navigator.onLine;
}

const withOfflineHandling = (FC: React.FC) =>
  function() {
    const isOnline = useSyncExternalStore(subscribe, getSnapshot);
    if (!isOnline) {
      logger.warn('Gone offline.');
    }
    return isOnline ? <FC /> : <TellyAd loaded={false} reason='offline' />;
  };

export default withOfflineHandling;
